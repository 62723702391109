import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import App from './App';

const container = document.getElementById('root'); // Get the root element

if (container) {
  const root = ReactDOM.createRoot(container); // Create the root
  root.render(
      <App />
  );
}
