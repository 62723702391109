import axios from 'axios'
import { FootballScore, MLBScore, NBAScore, NCAABScore, NHLScore, Sport } from './scores';
import { url } from 'inspector';

// const proxyURL = localHost ? `http://localhost:3000/api/proxy` : `https://draftdeck-proxy.vercel.app/api/proxy`;
const proxyURL = `https://tesseract-api.com/v1/espn/get-scores?sportType=`;
// const proxyURL = `https://u5m1rc2bg3.execute-api.us-east-1.amazonaws.com/dev/espn/get-scores?sportType=`;

  
export const testingFunction = (games: any[]): any[] => {
    
      //no games
      if (!games)
      {
        return [];
      }
  
      const gamesData: any[] = games.map(game => {
  
      //blank game data
      if (!game || !game.competitions)
      {
        return [];
      }
      const gameDate = game.date;
      const seasonType = game.season?.slug || "";
      // const notes = notesObject[0]?.headline || "";

      const gameData = game.competitions[0];
      const teams: any[] = gameData.competitors;

      const notesObject = gameData.notes;

      const notes = notesObject[0] ? "\n" + notesObject[0].headline : "";
  
      const awayTeamData = teams.find(t => t.homeAway === "away")
      const homeTeamData = teams.find(t => t.homeAway === "home")
  
      const awayTeam = awayTeamData.team.abbreviation;
      const homeTeam = homeTeamData.team.abbreviation;
  
      const awayScore = parseInt(awayTeamData.score);
      const homeScore = parseInt(homeTeamData.score);
  
      let gameStatus = "STATUS_FINAL"
      // let mystatus = "STATUS_IN_PROGRESS"
  
      // switch (mystatus)
      switch (gameStatus)
      {
        case "STATUS_FINAL":
          gameStatus = "STATUS_IN_PROGRESS";
          // gameStatus = game.status.type.name;
          break;
        case "STATUS_HALFTIME":
          gameStatus = game.status.type.name;
          break;
        case "STATUS_END_OF_PERIOD":
          gameStatus = game.status.type.name;
          break;
        case "STATUS_IN_PROGRESS":
          gameStatus = game.status.type.name;
          break;
        case "STATUS_SCHEDULED":
          gameStatus = game.status.type.name;
          break;
        case "STATUS_SHOOTOUT":
          gameStatus = game.status.type.name;
          break;
        default:
          {
            const basicallyInProgress = ["STATUS_BEGINNING_OF_PERIOD", "STATUS_END_OF_PERIOD", "STATUS_OVERTIME", "STATUS_FIRST_HALF", "STATUS_SECOND_HALF",
              "STATUS_INTERMEDIATE"
            ]
            const value = game.status.type.name || "";
            if (basicallyInProgress.includes(value))
            {
              gameStatus = value;
            }
          }
          break;
      }
  
      const situation = gameData.situation;

      // let lastPlay = "";
      // const lastPlaySafe = situation?.lastPlay?.alternativeType?.abbreviation;
      // lastPlay = lastPlaySafe ? lastPlaySafe : "";
  
      let sportSpecificFields = {};

      sportSpecificFields = enhanceData(game, situation);
      
      return {awayTeam, homeTeam, awayScore, homeScore, gameStatus, gameDate, seasonType, notes, //basic fields
              ...sportSpecificFields};
  
    });
    return gamesData;

  };

const enhanceData = (game: any, situation: any): any => {

    // return addNFLData(game, situation);
    // return addMLBData(game, situation);
    return addNBAData(game, situation);
    // return addNCAAMData(game, situation);
    // return addNHLData(game, situation);
    

}

const addNBAData = (game: any, situation: any): any => {
  let quarter = game.status.period;
  let time = game.status.displayClock;
  let lastPlay = ""

  if (situation) {
     lastPlay = situation.lastPlay.athletesInvolved[0].displayName || "";
  }

  return {quarter, time, lastPlay}
}

const addMLBData = (game: any, situation: any): any => {

  let firstBase = false;
  let secondBase = false;
  let thirdBase = false;
  let balls = 0;
  let strikes = 0;
  let outs = 0;
  let pitcher = "";
  let batter = "";
  
  // Game is live
  if (situation) {
    firstBase = situation.onFirst || false;
    secondBase = situation.onSecond || false;
    thirdBase = situation.onThird || false;
    outs = situation.outs || 0;
    balls = situation.balls || 0;
    strikes = situation.strikes || 0;
    pitcher = situation.pitcher?.athlete.displayName || "";
    batter = situation.batter?.athlete.displayName || "";
  }

  const inningStatus = game.status.type.shortDetail || "";
  const inning: number = game.status.period || 0;

  return { firstBase, secondBase, thirdBase, balls, strikes, outs, pitcher, batter, inningStatus, inning };
}

const addNFLData = (game: any, situation: any): any => {

  let quarter = game.status.period;
  let time = game.status.displayClock;
  let awayPossession = true;
  let ballOn = "";
  let downAndDistance = "";

  if (situation) {
    // awayPossession = situation.possession; //need to check this live
    // ballOn = situation.ballOn;
    // downAndDistance = situation.downAndDistance;
  }



  return {quarter, time, awayPossession, ballOn, downAndDistance}
}

const addNCAAMData = (game: any, situation: any): any => {
  let half = game.status.period;
  let time = game.status.displayClock;

  if (situation) {
    // lastPlay = game.situation.lastPlay.text;
  }

  return {half, time}
}

const addNHLData = (game: any, situation: any): any => {
  let period = game.status.period;
  let time = game.status.displayClock;
  let awayPowerPlay = false;
  let homePowerPlay = false;

  if (situation) {
    // powerPlayText = situation.powerPlayText; //made this up

  }

  return {period, time, awayPowerPlay, homePowerPlay}
}

export const getScoresBySport  = async (sport: Sport, dateString: string)  => {
  try { 
          const formattedDate = toDateFormatYYYYMMDD(dateString);

          const scoreData = await axios(`${proxyURL}${sport}&gameDate=${formattedDate}`);
          return scoreData.data;


  } catch (error: any) {
    console.error(error);
    return [];
  }
}; 

function toDateFormatYYYYMMDD(dateString: string | null): string {
  let date: Date;

  if (dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    date = new Date(year, month - 1, day);
  } 

  //first refresh, date is empty
  else 
  {
    date = new Date();
    date.setHours(date.getHours() - 6);
  }

  date.setHours(0, 0, 0, 0);

  const formattedDate = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;

  return formattedDate;
}
