import React from 'react';

interface DatePickerProps {
  onDateChange: (date: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ onDateChange }) => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDateChange(event.target.value); 
  };

  return (
      <input style={{height: "1.75em", margin:"1em", fontFamily: 'Consolas, monospace'}}
        type='date'
        onChange={handleDateChange} 
      />
  );
};

export default DatePicker;
