import React, { useState, useEffect } from 'react';

interface DropdownProps {
  options: string[];
  defaultValue?: string; // Optional default value
  onChange: (value: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, defaultValue = '', onChange }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
      onChange(defaultValue); // Optionally trigger onChange when default value is set
    }
  }, [defaultValue, onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <select style={{margin: "1em", height: "2em", fontFamily: 'Consolas, monospace'}}  value={selectedOption} onChange={handleChange}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
