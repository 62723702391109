import React, { useState, useEffect } from 'react';
import { FootballScore, MLBScore, NBAScore, NCAABScore, NHLScore, Sport, SportScore } from './data-components/scores';
import nflData from './nfl-json.json'
import nbaJson from './data-components/nba-json.json'
import nhlJSON from './data-components/nhl-scores-march.json'
import ncaamData from './ncaam-json.json'
import mlbHR from './data-components/mlb.json'
import wnba from './data-components/wnba.json'
// import collegeBBScores from './data-components/ncaab-scores.json'
import { getScoresBySport, testingFunction } from './data-components/apiData';
import Dropdown from './gui-components/Dropdown';
import Button from './gui-components/Button';
import DatePicker from './gui-components/DatePicker';
import './App.css'


const sportsSupported = ["nfl", "ncaaf", "mlb", "nba", "ncaam", "nhl", "wnba", "ncaaw"];

const App: React.FC = () => {
  const [scores, setScores] = useState<string>('Loading scores...');
  const [selectedSport, setSelectedSport] = useState<string>("");
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const testing = false;  

  const sportClassMap: Record<Sport, { fromObject: (obj: any) => any }> = {
    nfl: FootballScore,
    mlb: MLBScore,
    nba: NBAScore,
    ncaam: NCAABScore, 
    nhl: NHLScore,
    ncaaf: FootballScore,
    ncaaw: NBAScore,
    wnba: NBAScore
  };

  const initializeScoreData  = async ()  => {
    try {
        const sportString = selectedSport.toLocaleLowerCase() as Sport;
        const scoreDataRaw: any[] = await getScoresBySport(sportString, selectedDate);

        const ScoreClass = sportClassMap[sportString];

        const scores =  scoreDataRaw.map(game => ScoreClass.fromObject(game));
        // Sorting the array
        scores.sort((a, b) => {
          // First, compare by gameStatus
          if (a.gameStatus === 'STATUS_FINAL' && b.gameStatus !== 'STATUS_FINAL') {
            return 1; // a should come after b
          } else if (a.gameStatus !== 'STATUS_FINAL' && b.gameStatus === 'STATUS_FINAL') {
            return -1; // a should come before b
          } else {
            // If both have the same gameStatus, sort by convertToLocalTime
            return a.gameDate.localeCompare(b.gameDate);
          }
        });

        const liveGames = scores.filter((x: SportScore) => x.gameStatus === "STATUS_IN_PROGRESS");
        const currentGameVsTotal = `${scores.length},  ${liveGames.length} live`

        const asciiScores: string[] = scores.map(score => score.getAsciiScoreboard());
        const newScores = !asciiScores || asciiScores.length === 0 ? 
                        `no ${sportString} games` : 
                        `${selectedSport} games [${currentGameVsTotal}]\n${asciiScores.join('\n\n')}`;
        setScores(newScores);
  
    } catch (error: any) {
      console.error(error);
      return [];
    }
  }; 

  const handleDropdownChange = (value: string) => {
    setSelectedSport(value);
  };

  const handleAutoRefreshClick = () => {
    setAutoRefresh(x => !x)
  };

  const handleDateChange = (date: string) => {
    setSelectedDate(date);
  };

  const saveUserSettings= () => {
    if (sportsSupported.includes(selectedSport))
    {
      localStorage.setItem('skeletonscores-sport', selectedSport);
      localStorage.setItem('skeletonscores-refresh', JSON.stringify(autoRefresh));
    }
  }

  const loadUserSettings = () => {

    const savedSport = localStorage.getItem('skeletonscores-sport');
    const savedAutoRefresh = JSON.parse(localStorage.getItem('skeletonscores-refresh') || 'false');

    
    if (savedSport)
    {
      setSelectedSport(savedSport);
    }
    else
    {
      setSelectedSport("nfl");
    }

    setAutoRefresh(savedAutoRefresh);
  }

  //call apis on startup if blank (new browser)
  useEffect(() => {
        loadUserSettings();
          
    if (testing)
      { 
        const gameData: any[] = testingFunction(wnba);
        const testScores =  gameData.map(game => NBAScore.fromObject(game))
        

        testScores.sort((a, b) => {
          // First, compare by gameStatus
          if (a.gameStatus === 'STATUS_FINAL' && b.gameStatus !== 'STATUS_FINAL') {
            return 1; // a should come after b
          } else if (a.gameStatus !== 'STATUS_FINAL' && b.gameStatus === 'STATUS_FINAL') {
            return -1; // a should come before b
          } else {
            // If both have the same gameStatus, sort by convertToLocalTime
            return a.gameDate.localeCompare(b.gameDate);
          }
        });
        const testASCII: string[] = testScores.map(score => score.getAsciiScoreboard());
        setScores(testASCII.join('\n'));
      }
      else
      {
        
      }
    
  }, []); 

  //save user settings
  useEffect(() => {
    saveUserSettings()
  }, [autoRefresh, selectedSport]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedSport) {
        await initializeScoreData();
      }
    };
  
    let isActive = true; // To control the loop
  
    const startAutoRefresh = async () => {

      while (isActive && autoRefresh) {
        await fetchData();
        await new Promise(resolve => setTimeout(resolve, 10000));
      }
    };
  
    if (!testing && !autoRefresh) {
      fetchData();
    }
  
    if (autoRefresh && !testing) {
      startAutoRefresh(); // Start the auto-refresh loop
    }
  
    return () => {
      isActive = false; // Stop the loop on unmount or when dependencies change
    };
  }, [selectedSport, autoRefresh, selectedDate]);
  

  return (
    <div className='container'>
        <div className='centered'>
          <Dropdown defaultValue={selectedSport} options={sportsSupported} onChange={handleDropdownChange} />
          <Button text={`refresh ${autoRefresh ? "on" : "off"}`} onClick={handleAutoRefreshClick}></Button>
          <DatePicker onDateChange={handleDateChange}></DatePicker>
        </div>
      {/* <pre style={{fontFamily: "Consolas, monospace"}}>{selectedSport + " - " + "autorefresh: " + autoRefresh}</pre> */}
      <div   style={{maxWidth: 'calc(38ch)', whiteSpace: 'pre-wrap' as React.CSSProperties['whiteSpace'], paddingBottom:"2em"}}>
                {scores ? scores : `loading ${selectedSport ? selectedSport.toLocaleLowerCase() :""}...`}
      </div>
      <div className='sticky-footer'>skeletonscores - Jeffrey Antetomaso</div>
    </div>
  );
}

export default App;
