import React from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <button style={{height: "2em", fontFamily: 'Consolas, monospace', width: "10em"}} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
